import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IInputElement } from '../../../shared/lib/types';
import { removeSpaces } from '../../../shared/lib/utils';
import { validateInput, ValidatorTypesEnum } from '../../../shared/lib/validators';
import TextInput from '../../form/TextInput';
import api from '../shared/lib/api';
import { newInputElement, updateInput } from './kundedetaljer/kundeFormHelper';
import Spinner from '../spinner/Spinner';
import FeilmeldingSide from '../feilside/FeilmeldingSide';
import TilbakeTil from './Tilbake';

const LeggTilKunde = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);

    const [orgnummer, setOrgnummer] = useState<IInputElement>({
        ...newInputElement('', 'Kundes organisasjonsnummer', null, null, [ValidatorTypesEnum.orgnr]),
    });
    const [egetOrgnummer, setEgetOrgnummer] = useState<IInputElement>({
        ...newInputElement('', 'Eget organisasjonsnummer', null, null, [ValidatorTypesEnum.orgnr]),
    });

    const onLookupClick = () => {
        const orgnr = { ...orgnummer };
        orgnr.validators.push(ValidatorTypesEnum.required);

        setOrgnummer({
            ...validateInput(orgnr),
        });

        if (!orgnr.validationError) {
            navigate(`/opprett-kunde?lookup=${removeSpaces(orgnummer.value)}`);
        }
    };

    const onManualClick = () => {
        const orgnr = { ...orgnummer };
        orgnr.validators = [ValidatorTypesEnum.orgnr];

        setOrgnummer({
            ...validateInput(orgnr),
        });

        if (!orgnr.validationError) {
            navigate(`/opprett-kunde?orgnr=${removeSpaces(orgnummer.value)}`);
        }
    };
    const onImportClick = () => {
        const orgnr = { ...egetOrgnummer };
        orgnr.validators.push(ValidatorTypesEnum.required);

        setEgetOrgnummer({
            ...validateInput(orgnr),
        });

        if (!orgnr.validationError) {
            navigate(`/import-kunde/${removeSpaces(egetOrgnummer.value)}`);
        }
    };
    useEffect(() => {
        (function getBruker() {
            api.getInnloggetBruker()
                .then((res) => {
                    setEgetOrgnummer({
                        ...newInputElement(res.regnskapsforetakOrganisasjonsnummer, 'Eget organisasjonsnummer', null, null, [
                            ValidatorTypesEnum.orgnr,
                        ]),
                    });
                })
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Legg til kunde';
        })();
    }, []);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <>
            <div className="page-header">
                <div className="container">
                    <h1>Legg til kunde</h1>
                </div>
            </div>
            <TilbakeTil url={`/kunder`} tekst="Tilbake til kundeoversikt" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-xl-8">
                        <h2>Importer fra Foretaksregisteret</h2>
                        <p>
                            Vennligst bekreft organisasjonsnummer for å hente tilkoblede kunder. Når du bekrefter organisasjonsnummeret ditt, hentes
                            alle kunder i Foretaksregisteret som har registrert byråets organisasjonsnummer som enten regnskapsfører eller revisor.
                        </p>
                        <TextInput
                            {...egetOrgnummer}
                            width="shorttext"
                            onInputChange={(_name, value) => {
                                setEgetOrgnummer({
                                    ...updateInput(egetOrgnummer, value),
                                });
                            }}
                            onInputBlur={() => {
                                let input = { ...egetOrgnummer };
                                input.validateOnChange = true;
                                setEgetOrgnummer({
                                    ...validateInput(egetOrgnummer),
                                });
                            }}
                        />
                        <div className="mb-lg">
                            <button className="btn btn--primary" onClick={onImportClick}>
                                Les inn dine kunder fra foretaksregisteret
                            </button>
                        </div>

                        <hr />
                        <h2>Gjør oppslag eller legg inn manuelt</h2>
                        <p>
                            Oppgi organisasjonsnummer for å legge inn ny kunde. Vi gjør automatisk oppslag slik at du slipper å fylle inn alle
                            opplysninger selv.
                        </p>

                        <TextInput
                            {...orgnummer}
                            width="shorttext"
                            onInputChange={(_name, value) => {
                                setOrgnummer({
                                    ...updateInput(orgnummer, value),
                                });
                            }}
                            onInputBlur={() => {
                                let input = { ...orgnummer };
                                input.validateOnChange = true;
                                setOrgnummer({
                                    ...validateInput(orgnummer),
                                });
                            }}
                        />
                        <div className="hstack mb-lg">
                            <button className="btn btn--primary" onClick={() => onLookupClick()}>
                                Gjør oppslag
                            </button>
                            <button className="btn btn--secondary" onClick={() => onManualClick()}>
                                Legg inn manuelt
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LeggTilKunde;
