import { Link } from "react-router-dom";

interface ITilbake{
    readonly url: string;
    readonly tekst: string;
    readonly css?: string;
}

export default function Tilbake(props:ITilbake){
    const css = props.css ? props.css : 'container';
      
    return(
        <div className={`${css} tilbake`}>
        <div className="tilbake-til">
            <Link to={props.url}>
                <span className="icon icon-arrow-left" />
                <span className="tilbake-til__teks">{props.tekst}</span>
            </Link>
        </div>
    </div>
    )
}